:root {
  --white: #fff;
  --grey: #E7E7E7;
  --light-blue: #0072FF;
  --blue: #0052B8;
  --dark-blue: #002647;
  --orange: #FF4D00;
  --red: #FF0000;
}

@media (max-width: 1200px) {
	.about-us {
    min-height: 770px;
	}

	.navbar-nav .nav-link {
		font-size: 14px;
	}
	
	
}

@media (max-width: 1014px) {


	.nav-link__icon img{
		max-height: 20px;
		margin-right: 10px;
	}

	.navbar-expand-lg {
	background: linear-gradient(0deg, rgba(0, 38, 71, 0.9), rgba(0, 38, 71, 0.9));
}

.navbar-brand {
	margin-right: 10px;
}

.navbar-nav .nav-link {
	text-align: center;
	margin: auto;

}

.navbar-toggler {
	color: var(--white);
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); 
}

.about-cover img {
	object-position: 88%;
}

.cleaner-cover img {
	object-position: 88%;
}

.valet-cover img {
	object-position: 40%;
}

.guard-cover img {
	object-position: 70%;
}

.promomodel-cover img {
	object-position: 65%;
}

.barman-cover img {
	object-position: 20%;
}

.cashier-cover img {
	object-position: 60%;
}

    .main-block__title {
			font-size: 50px;
			line-height: initial;
			text-align: center;
    }

    .feedback-first__block-flex {
        flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		gap: 20px;
    }
	.feedback-input, .feedback__text {

		margin: auto;
	}

    .feedback-first__block {
        margin-top: 40px;
        margin-left: 41px;
        margin-right: 40px;
    }


    .feedback-first {
        width: max-content;
        max-width: 635px;
		top: 420px;
    }

		.feedback-first__about-us {
			max-width: 630px;
		}

		.feedback__text {
			text-align: center;
			margin-bottom: 30px;
		}

    .feedback-first__button {
        margin-top: 20px;
				margin-left: 0px;
    }
		.feedback-second {
			min-height: 725px;
		}

		.feedback-second__title {
			font-size: 30px;
			margin-bottom: 40px;
			margin-top: -70px;
		}


		.simple-and-fast__title {
			font-size: 30px;
			margin-top: 30px;
	}

    .simple-and-fast__steps {
        margin-top: 20px;

    }

		.order__button {
			margin-top: 30px;
			margin-bottom: 30px;
	}

		.popular-services__title {
			font-size: 30px;
			margin-top: 40px;
			margin-bottom: 30px;
	}

		.services__button {
			margin-top: 30px;
			margin-bottom: 30px;
	}
    .advantage {
        width: 100%;
        max-width: 100%;
				margin-bottom: 15px;
    }

		.advantages__title {
      font-size: 24px;
			margin-top: 40px;
			margin-bottom: 40px;
		}

		.list-advantages {
			margin-top: 30px;
		}

    .advantage__image {
        max-width: 50px;
    }

    .advantages__video {

        margin-top: 40px;
    }
    .step img{

        max-width: 50px;
    }

    .icon-next {
        max-width: 30px;
    }

    .service-sanitation__title {

        text-align: center;
    }

		.why-we__block {
			flex: none;
		}

		.why-we__image {
			margin: 40px auto;
			display: block;
	}

	.why-we__text {
    margin-top: 40px;
	}

		.divider-dark {
			margin-top: auto;
		}

		.about-us__title {
			font-size: 24px;
			margin-top: 30px;
			margin-bottom: 30px;
	}

	.about-us__text {
    font-size: 20px;
		margin-bottom: 110px;
	}

	.page2__title {
    font-size: 40px;
    text-align: center;
}

	.page3__title {
    font-size: 40px;
		text-align: center;
}

.services__title {
	font-size: 30px;
	margin-top: 30px;
	
}

.services-list {
    margin-top: 30px;
}

.services__block {
	min-height: 600px;
	
}

.staff__title {
	font-size: 30px;
	margin-top: 40px;
	margin-bottom: 20px;
}

.we-are-trusted__title {
	font-size: 40px;
text-align: center;
}

.we-are-trusted__title2 {
	font-size: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.portfolio__title {
	font-size: 40px;
	text-align: center;
}

.portfolio {
  margin-top: 10px;
}

.slider__title {
	font-size: 30px;
}
.staff-helper__title {
	font-size: 40px;
	text-align: center;
}

.staff-helper__text {
	font-size: 20px;
	margin-top: 20px;
}

.staff-loader__title {
	font-size: 40px;
	text-align: center;
}

.staff-loader__text {
	font-size: 20px;
	margin-top: 20px;
}

.service-parking__title {
	font-size: 40px;
	text-align: center;
}

.service-parking__text {
	font-size: 20px;
	margin-top: 20px;
}

.service-sanitation__title {
  font-size: 40px;
	text-align: center;
}

.service-sanitation__text {
  font-size: 20px;
	margin-top: 20px;
}

.service-security__title {
  font-size: 40px;
	text-align: center;
}

.service-security__text {
  font-size: 20px;
	margin-top: 20px;
}

.service-cleaning__title {
  font-size: 40px;
	text-align: center;
}

.service-cleaning__text {
  font-size: 20px;
	margin-top: 20px;
}

.service-promo__title {
  font-size: 40px;
	text-align: center;
}

.service-promo__text {
  font-size: 20px;
	margin-top: 20px;
}

.service-logistics__title {
  font-size: 40px;
	text-align: center;
}

.service-logistics__text {
  font-size: 20px;
	margin-top: 20px;
}

.service-photo__title {
  font-size: 40px;
	text-align: center;
}

.service-photo__text {
  font-size: 20px;
	margin-top: 20px;
}

.team__title {
	margin-top: 260px;
	margin-bottom: 20px;
}

}
/* ------------------------------------------------------------------------ */
@media (max-width: 992px) { 

	.navbar-brand-soc-mobile {
		display: flex;
		padding: 0;
		justify-content: center;
		align-items: center;
		height: 100%;
		margin: 0;
    margin-left: auto;
	}

	.nav-item-soc {
		display: none;
	}

	.simple-and-fast__steps {
		flex-direction: column;
		align-items: center;
	}

	.step {
		margin-bottom: 40px;
    margin-top: 15px;
	}

	.icon-next img {
		transform: rotate(90deg);
	}

}
/* ------------------------------------------------------------------------ */
@media (max-width: 767px) {
  .logo-head {
		margin-top: 4px;
	}

	.navbar-brand {
		max-width: 160px;
	}

    .feedback-first, .feedback-first__about-us, .feedback-second__block {
        max-width: 100%;
        padding: 25px 15px 50px 15px;
        bottom: auto;

    }

	.feedback-second__block {
		padding: 25px 15px 115px 15px;
	}

	.feedback-second__block .feedback__text {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		width: 90%;
	}

	.feedback-second__block-flex .feedback-input {
		max-width: none;
	}



	.feedback-second__button {
		
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translate(-50%);
		width: max-content;
	}


		.feedback-second {
			min-height: 860px;
		}

    .feedback-second__block-flex {
        flex-wrap: wrap;
    }

		.feedback__text {
			margin-bottom: 15px;
			
		}

		.feedback-second__contacts {
			display: none;
		}

    .feedback-second__block-flex_right, .feedback-second__block-flex_left {
        max-width: 100%;
        margin-right: auto;
        width: 100%;
    }

    .footer .footer__logo,   .footer .footer__text{
		flex: auto;
		font-size: 16px;
		width: 100%;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
    }


		.footer__text {
        margin-bottom: 20px;
				margin-top: 0px;
				order: 3;
		}

	.footer__phone, .footer__mail {
		margin: auto;
	}

    .footer__logo-img {
        margin-top: 40px;
        object-fit: contain;
        max-width: 110px;
        display: block;
        margin: 20px auto 0;
    }

  .footer .footer__contacts {
        width: 100%;
				margin-top: 20px;
    margin-bottom: 30px;
    }

    .copyright {

        margin-top: 10px;
    }

    .main-block__title {
        font-size: 40px;
        line-height: initial;
    }

    .feedback-first__block {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 15px;
    }

    .feedback-input-wrap {
        width: 100%;
        margin-bottom: 10px;
		margin-right: 0;
    }

    .feedback-input {
        gap: 10px;
        width: 100%;
        max-width: inherit;
    }

    .feedback-first {
        top: 390px;
		padding: 25px 15px 20px;
    }


		.services__button {
			margin-top: 30px;
			margin-bottom: 30px;
	}

		.list-advantages {
			margin-top: 30px;
		}

		.trusted-logo__item {
			margin-right: 0px;
	
				
		}

		.team__title {
			margin-top: 330px;
			margin-bottom: 20px;
		}
		
		.portfolio__item-title {
			font-size: 18px;
		}
}
/* ------------------------------------------------------------------------ */
@media (max-width: 479px) {

	.navbar-brand {
		max-width: 140px;
	}

	.feedback-first, .feedback-first__about-us, .feedback-second__block {
		left: auto;
		-webkit-transform: none;
		transform: none;
	}

	.main-block__title {
    font-size: 30px;
		text-align: center;
    
}

.why-we__buttons {
	justify-content: space-around;
	margin-top: 40px;
    margin-bottom: 40px;
}

.why-we__buttons .button2 {
	margin-right: 0px;
}

.services__buttons {
	justify-content: space-around;
	margin-top: 30px;
    margin-bottom: 30px;
}

.services__buttons  .button2 {
	margin-right: 0px;
}

.feedback-first {
	top: 360px;
}

.portfolio__item-title {
	font-size: 12px;
}

}
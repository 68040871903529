:root {
  --white: #fff;
  --grey: #E7E7E7;
  --light-blue: #0072FF;
  --blue: #0052B8;
  --dark-blue: #002647;
  --orange: #FF4D00;
  --red: #FF0000;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

html,
body {
  height: 100%;
  line-height: 1.5;
  font-size: 16px;
  color: var(--dark-blue);
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  background-color: var(--white);
  min-width: 320px;
  /* overflow: hidden; */
}
/* ------------------------------------------------------------------------ */
.wrapper {
  min-height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

._container {

}

._ibg {
  position: relative;
}

._ibg img{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.button1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 50px;
  gap: 10px;
  background: var(--orange);
  box-shadow: 0px 6px 24px rgba(255, 77, 0, 0.5);
  border: none ;
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: var(--white);
  line-height: 18px;
  text-align: center;
}

.button1:hover, .button1:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 50px;
  gap: 10px;
  border: none ;
  box-shadow: none;
  background: linear-gradient(259.05deg, #FF4D00 26.25%, #FF0000 114.07%);
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: var(--white);
  line-height: 18px;
  text-align: center;
}

.button2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 50px;
  gap: 10px;
  box-shadow: none;
  border: 3px solid var(--dark-blue);
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: var(--dark-blue);
  line-height: 18px;
  text-align: center;
  background: none;
}

.button2:hover, .button2:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 50px;
  gap: 10px;
  border: 3px solid var(--dark-blue);
  box-shadow: none;
  background: rgba(0, 38, 71, 0.25);
  border-radius: 20px;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: var(--dark-blue);
  line-height: 18px;
  text-align: center;
}

/* ------------------------------------------------------------------------ */
.navbar {

}

.logo-head {
margin-top: 0px;
}

.navbar-brand {
	margin-top: 5px;
    max-width: 190px;
    margin-right: 0px;
}


.container-fluid {
  max-width: 1400px;
}

.navbar-nav .nav-link {
  color: var(--white);
  font-weight: 500;
	white-space: nowrap;
}

.nav-link {
  margin: 0px 0px 0px 30px;
}

.nav-link__contacts {
	margin: 0px 0px 0px 10px;
}

.navbar-brand-soc-mobile {
  display: none;
}

.nav-link__icon {
	margin: 0px 0px 0px 10px;
	min-width: 30px;
	max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.menu li a::after {
  content: '';
  display: block;
  width: 100%;
  background-color: var(--light-blue);
  height: 2px;
  transition: all .3s;
  transform: scale(0);
}

.menu li a:hover::after {
  transform: scale(1);
}

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 50;
  background: linear-gradient(180deg, #002647 0%, rgba(0, 38, 71, 0) 100%);
}

.header__container {
  align-items: center;
}
/* ------------------------------------------------------------------------ */
.page1 {
  flex: 1 1 auto;
}

.main-block__container {
  padding: 112px 0px 160px 0px;
  position: relative;
  z-index: 2;
  min-height: 600px;
}

.main-block {
  position: relative;
  background-color: var(--blue);

}

.main-block__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  max-width: 980px;
  margin: auto;

}

.main-block__title span {
  color: var(--orange);
}

.page2__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  max-width: 172px;
  margin: auto;
  margin-top: 200px;
}

.page3__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 0px;
}

.main-block__image {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  opacity: 0.5;
}
/* ------------------------------------------------------------------------ */
.feedback-first {
  display: flex;
  position: absolute;
  width: max-content;
  max-width: 920px;
  min-height: 160px;
  top: 520px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 24px rgba(0, 38, 71, 0.5);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  z-index: 3;
  transform: translateX(-50%);
  left: 50%;
}
.feedback-first__about-us {
  display: flex;
  position: absolute;
  width: max-content;
  max-width: 920px;
  min-height: 160px;
  margin-top: -80px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 24px rgba(0, 38, 71, 0.5);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  z-index: 3;
  transform: translateX(-50%);
  left: 50%;
}
.feedback-first__block{
  justify-content: center;
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 60px;
  padding: 0;
}

.feedback-first__block-flex {
  display: flex;
}

.feedback-input-wrap {
  margin-right: 10px;
}



.feedback-input {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  padding: 15px 22px 17px 18px;
  gap: 10px;
  max-width: 255px;
  min-height: 50px;
  background: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 38, 71, 0.5);
  border-radius: 20px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}


.feedback__text {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.feedback-first__button {
  margin-left: 20px;

}
/* ------------------------------------------------------------------------ */
.simple-and-fast {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

.simple-and-fast__title {
  left: 550px;
  top: 728px;
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--light-blue);
  text-align: center;
}

.simple-and-fast__steps {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.order__button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.download__button {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.icon-next {
  display: flex;
  margin-top: -25px;
  margin-left: 9px;
  margin-right: 9px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* ------------------------------------------------------------------------ */
.popular-services {
  background: var(--grey);
}

.popular-services__title {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--dark-blue);
  text-align: center;
  margin-top: 60px;
	margin-bottom: 40px;
}

.slider {
  position: relative;
  padding: 0 50px;
  margin-top: 60px;
}

.slick-slider {
  position: relative;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  font-size: 0;
  width: 50px;
  height: 50px;
  margin: -50px 0px 0px 0px;
}

.slick-dots {
  display: none!important;
}

.slick-slide {
}

.slider-portfolio__item {
  display: inline-flex!important;
  align-items: center;
  justify-content: center;
}

.slider-portfolio__item img {
  border-radius: 15px;

}


.slider-portfolio {
  margin-bottom: 65px;
}

.slick-arrow.slick-prev {
  left: 0;
  background: url(/public/static/img/Angle-left-dark-blue.svg) 0 0 / 100% no-repeat;
}

.slick-arrow.slick-next {
  right: 0;
  background: url(/public/static/img/Angle-right-dark-blue.svg) 0 0 / 100% no-repeat;
}

.slick-list {
  overflow: hidden;
}
.slick-track {
  display: flex;
}


.service__text {
  position: absolute;
  background: linear-gradient(180deg, #0052B8 0%, rgba(0, 114, 255, 0.25) 100%);
  display: none;
  height: 100%;
  width: 100%;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--white);
	z-index: 10;
	
}

.service__text p {
  padding: 10px 10px;
}

.service__text-title {
  margin-top: 15px;
}

.service__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: var(--white);
  font-size: 20px;
	margin-bottom: 15px;
  z-index: 1;

}
.service__title-back {
  background: linear-gradient(180deg, rgba(0, 38, 71, 0) 68.23%, #002647 100%);
  height: 100%;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 0;
} 


.service {
  width: 255px;
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(0, 38, 71, 0.5);
  margin: 30px auto 35px;
}

.service:hover .service__text  {
  display: block;
}

.service:hover .service__title  {
  display: none;
}

.service__img {
  object-fit: contain;
	
}

.services__button {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.services {
	margin-top: -350px;
  position: relative;
	z-index: 11;
}

.services__block {
  max-width: 730px;
  min-height: 640px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 6px 20px rgba(0, 38, 71, 0.5);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
}


.services__title {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--dark-blue);
  text-align: center;
  margin-top: 40px;
}

.services-list__item {
  max-width: 540px;
  width: 100%;
  min-height: 48px;
  margin-bottom: 16px;
  gap: 10px;
  background: var(--white);
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
	position: relative;
}

.list__item-link {
	display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px 40px;
    color: var(--dark-blue);
}

.list__item-link:hover {
	position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px 40px;
    color: var(--white);
}


.services-list__item:hover {
  background: linear-gradient(268.57deg, #0072FF 63.28%, #0052B8 104.32%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  color: var(--white);
}

.services-list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* ------------------------------------------------------------------------ */
.advantages {
  
}
.list-advantages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}

.advantage__image {
  margin-right: 10px;
}
.advantage {
  display: flex;
  max-width: 31%;
  /* box-sizing: content-box; */
  flex-grow: 1;
}

.advantage span{
	margin-top: 15px;
	margin-left: 5px;
}

.advantage:not(:last-child) {
  margin-right: 30px;
}

.advantages__video {
  min-height: 440px;
  border-radius: 15px;
  margin: auto;
  margin-top: 60px;
  object-fit: cover;
  max-height: 600px;
  width: 100%;
}

.advantages__video::-webkit-media-controls {
  display:none !important;
}

.advantages__title {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  margin-top: 55px;
  margin-bottom: 60px;
}

.advantages__title span {
  color: var(--light-blue);
}
/* ------------------------------------------------------------------------ */
.why-we {
  background-color: var(--grey);
}



.why-we__image {
  margin: 60px auto;
}

.why-we__text {
  margin-top: 60px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.why-we__text span {
  color: var(--light-blue);
}
.why-we__buttons {
  display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 60px;
    justify-content: center;
}

.why-we__buttons .button2 {
  margin-right: 40px;
}

.divider {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.divider-dark {
	display: flex;
  justify-content: center;
  margin-top: 60px;
}

/* ------------------------------------------------------------------------ */
.feedback-second {
  position: relative;
  padding: 112px 0px 248px;
  min-height: 750px;
  background-color: var(--blue);
}

.feedback-second__title{
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--white);
  text-align: center;
  position: relative;
  margin-top: -40px;
}

.feedback-second__block {
  position: absolute;
  max-width: 920px;
  width: max-content;
  min-height: 440px;
  padding: 25px 70px 45px 70px;
  bottom: 112px;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0.9;
  box-shadow: 0px 6px 24px rgba(0, 38, 71, 0.5);
  backdrop-filter: blur(7.5px);
  border-radius: 15px;
  z-index: 3;
  transform: translateX(-50%);
  left: 50%;
}

.feedback-second__block-flex {
  display: flex;
  margin-top: 35px;
}

.feedback-second__block-flex .feedback-input{
  max-width: 320px;
  width: 100%;
  margin-bottom: 25px;
}

.feedback-input__textarea {
  min-height: 200px;
}

.feedback-second__block-flex_left {
  max-width: 320px;
  margin-right: 40px;
  width: 50%;
}

.feedback-second__block-flex_right {
  max-width: 320px;
  width: 50%;
}

.feedback-second__button {
  display: flex;
  justify-content: center;
}

.feedback-second__title2 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: var(--dark-blue);

}

.feedback-second__image {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.feedback-second__phone a {
  color: var(--dark-blue);
}
.feedback-second__mail {

  margin-top: 10px;
}

.feedback-second__mail a {
	color: var(--dark-blue);
}

.phone__image {
  margin-right: 10px;
}

.mail__image {
  margin-right: 10px;
}
/* ------------------------------------------------------------------------ */
.footer {

  min-height: 190px;
  background-color: var(--dark-blue);
  color: var(--white);
}

.footer__logo-img {
  height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
  object-fit: contain;
}

.footer__text {
	margin-top: 40px;
  font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 30px;
    color: var(--white);
}

.copyright {
	font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
		text-align: center;
    position: relative;
		margin-top: 60px;
}
.footer__contacts {
  margin-top: 40px;
  margin-bottom: 40px;
	text-align: right;
}

.footer__phone {
  display: flex;
  flex-direction: row-reverse;
  width: 190px;
  margin-left: auto;
  justify-content: flex-end;
}

.footer__phone a {
	color: var(--white);
}

.footer__mail {
  display: flex;
  flex-direction: row-reverse;
  width: 190px;
  margin-left: auto;
	margin-top: 10px;
  justify-content: flex-end;
}

.footer__mail a {
	color: var(--white);
}
/* ------------------------------------------------------------------------ */
.about-us {
  background-color: var(--grey);
  min-height: 700px;
}

.about-us__title {
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.about-us__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.about-us__text span {
  color: var(--light-blue);
}

.team {
  margin-bottom: 60px;
	
}

.team__title {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--dark-blue);
  text-align: center;
  margin-top: 140px;
  margin-bottom: 60px;
}
/* ------------------------------------------------------------------------ */
.staff {
	
}

.staff__main-text {
	
	font-weight: 500;
  font-size: 24px;
  line-height: 28px;
	margin-top: 10px;
	margin-bottom: 60px;
	text-align: center;
}

.staff__title {
	font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--dark-blue);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.staff__text {
  position: absolute;
    background: linear-gradient(180deg, #002647 0%, rgba(0, 38, 71, 0.25) 100%);
    display: none;
    height: 100%;
    width: 100%;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--white);
    z-index: 10;
}

.staff__text span {
  font-style: italic;
	font-size: 18px;
}

.staff__text p {
	padding: 10px 10px;
}

.staff__text-title {
  margin-top: 15px;
}


.staff__title2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: var(--white);
	font-size: 20px;
	margin-bottom: 15px;
  z-index: 1;
	
}

.staff__card {
  width: 255px;
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 6px 20px rgba(0, 38, 71, 0.5);
	margin: 30px auto 35px;
}

.staff__card-back {
  background: linear-gradient(180deg, rgba(0, 38, 71, 0) 68.23%, #002647 100%);
  height: 100%;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 0;
}
.staff__card:hover .staff__text  {
  display: block;
}

.staff__card:hover .staff__title2  {
  display: none;
}

.staff__img {
  object-fit: contain;
}
/* ------------------------------------------------------------------------ */
.angle-services {
  position: absolute;
  right: 15px;
  top: 50%;
	transform: translateY(-50%);
}

.service-parking__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-parking__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-parking__text span {
  color: var(--light-blue);
}

.service-sanitation__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-sanitation__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-sanitation__text span {
  color: var(--light-blue);
}

.service-security__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-security__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-security__text span {
  color: var(--light-blue);
}

.service-cleaning__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-cleaning__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-cleaning__text span {
  color: var(--light-blue);
}

.service-promo__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-promo__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-promo__text span {
  color: var(--light-blue);
}

.service-logistics__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-logistics__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-logistics__text span {
  color: var(--light-blue);
}

.service-photo__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.service-photo__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
}

.service-photo__text span {
  color: var(--light-blue);
}

.services__buttons {
  display: flex;
	flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.services__buttons .button2 {
  margin-right: 40px;
}

/* ------------------------------------------------------------------------ */
.we-are-trusted {
	margin-bottom: 60px;
}

.we-are-trusted__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  max-width: 440px;
  margin: auto;
	margin-top: 200px;
}

.we-are-trusted__title2 {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 40px;
  color: var(--dark-blue);
  text-align: center;
  margin-top: 60px;
	margin-bottom: 60px;
}

.we-are-trusted__block {

}

.trusted-logo {
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
}

.trusted-logo__item {
  max-width: 232px;
  object-fit: contain;
	margin-right: 30px;
    margin-bottom: 30px;
		margin-top: 30px;
}
/* ------------------------------------------------------------------------ */
.portfolio__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  max-width: 343px;
  margin: auto;
	margin-top: 200px;
}

.portfolio {
  margin-top: 30px;
}

/*.portfolio__image {*/
/*	background-image: url('./img/KSKlogo2.png');*/
/*}*/

.portfolio-cover img{
  object-fit: contain;

}

.portfolio__block {
}

.slider__title {
	font-family: 'Ubuntu';
	font-weight: 500;
	font-size: 40px;
	color: var(--dark-blue);
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
}

.portfolio__item-title {
	position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: var(--dark-blue);
  font-size: 24px;
	margin-bottom: 15px;
  z-index: 1;
}

.portfolio__item-back {
	background:linear-gradient(180deg, rgba(255, 255, 255, 0) 85.94%, #FFFFFF 100%);
  height: 100%;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 0;

}
/* ------------------------------------------------------------------------ */
.staff-helper__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.staff-helper__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.staff-helper__text span {
  color: var(--light-blue);
}

.staff-loader__title {
  font-weight: 500;
  font-size: 60px;
  line-height: 69px;
  color: var(--white);
  text-align: center;
  margin: auto;
	margin-top: 200px;
}

.staff-loader__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.staff-loader__text span {
  color: var(--light-blue);
}